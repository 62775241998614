/* eslint-disable array-callback-return */
<template>
  <div class="">
    <b-row>
      <b-col
        cols="12"
        class="mb-2"
      >
        <v-select
          v-model="selected"
          multiple
          taggable
          push-tags
          placeholder="Agregar Ingredientes"
          label="title"
          :create-option="item => ({ title: item.replace(/[^a-zA-Z0-9\s]/g, '')})"
        />
        <span
          v-if="$v.selected.$error && !$v.selected.required"
          class="help-block text-danger"
        >Campo requerido</span>
      </b-col>
      <b-col
        cols="12"
        class="mb-1 text-center"
      >
        <div class="title">
          Ingredientes
        </div>
      </b-col>
      <b-col
        v-for="(item, index) in selected"
        :key="index"
        cols="2"
        class="mb-1"
      >
        <div class="title2">
          <feather-icon icon="CheckCircleIcon" />
          {{ item.title ? item.title : item }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from 'vuelidate/lib/validators'

export default {
	components: {
		vSelect,
		BCol,
		BRow,
	},
	props: {
		recordingredient: {
			type: Array,
			default: null,
		},
	},
	data() {
		return {
			selected: this.recordingredient,
			ingredients: [],
		}
	},
	watch: {
		recordingredient(newDate) {
			this.selected = newDate
		},
	},
	methods: {
		validate() {
			const me = this
			me.$v.selected.$touch()
			const isValid = !me.$v.selected.$invalid
			me.ingredients = []

			me.selected.map(el =>
				el.title
					? me.ingredients.push({ title: el.title })
					: me.ingredients.push({ title: el }),
			)

			me.$emit('on-validate', JSON.stringify(me.ingredients), isValid)
			return isValid
		},
	},
	validations: {
		selected: {
			required,
		},
	},
}
</script>

<style lang="scss" scoped>
.title {
	font-size: 18px;
	font-weight: 700;
	color: #636363;
}

.title2 {
	font-size: 16px;
	font-weight: 700;
	color: #636363;
}
</style>

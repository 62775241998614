<template>
  <div class="col position-relative">
    <div class="card card__1 ">
      <div class="card-body pb-0">
        <full-calendar
          ref="refCalendar"
          class="full-calendar"
          :options="calendarOptions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import '@fullcalendar/core/vdom' // solves problem with Vite
import esLocale from '@fullcalendar/core/locales/es'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import moment from 'moment'

moment.locale('es')

export default {
  components: {
    FullCalendar,
  },
  props: {
    events: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        dateClick: this.handleDateClick,
        locale: esLocale,
        headerToolbar: {},
        events: this.events,
        eventClick: this.handleEventClick,
      },
      totalDays: '',
    }
  },
  watch: {
    events(newData) {
      this.calendarOptions.events = newData
    },
  },
  mounted() {},
  methods: {
    handleDateClick(arg) {
      this.$emit('modalProgramMenu', arg.dateStr)
    },
    handleEventClick(arg) {
      const { id, extendedProps } = arg.event
      this.$emit('detailOrEdit', id, extendedProps)
    },
    fillCalendar() {},
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";

.fc-toolbar-title {
  font-weight: 800 !important;
  text-transform: uppercase;
}

.fc-today-button {
  background-color: #7367f0 !important;
  border: none !important;
}

.fc-day-today {
  background-color: #7367f0 !important;
}

.card__1 {
  box-shadow: 0 5px 11px 0 rgba(145, 144, 144, 0.3), 0 4px 15px 0 rgba(133, 132, 132, 0.15);
}

.fc-daygrid-event {
  margin-bottom: 5px !important;
}
</style>

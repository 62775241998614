<script>

import FormWizardCreateDishe from '@/modules/diet_management_nutritionist/components/FormWizardCreateDishe.vue'

export default {
    inject: ['disheRepository', 'nutritionalInfoRepository'],

    components: { FormWizardCreateDishe },
    props: {
      id: {
        type: String,
        default: null,
      },
    },
		data() {
			return {
				isBusy: false,
        recordnamedishe: null,
        recordingredient: null,
        recordinfnutri: null,
			}
		},
    async mounted() {
      const me = this
      if (me.id) {
        const dishe = await me.disheRepository.find(me.id)
        me.recordnamedishe = {
          accompaniment: dishe.accompaniment != '0',
          image: dishe.image,
          name: dishe.name,
          type_accompaniment: dishe.type_accompaniment,
        }
        me.recordingredient = dishe.ingredients
        me.recordinfnutri = dishe.nutritionalinformation.length > 0
          ? JSON.parse(dishe.nutritionalinformation[0].data.nutritional_values)
          : null
      } else {
        me.recordnamedishe = null
        me.recordingredient = null
        me.recordinfnutri = null
      }
    },
    created() {
      this.$emit('IsBack', true)
    },

     methods: {
      isBack(e) {
       this.$emit('IsBack', e)
      },
       SubmitDish(id) {
        this.$emit('SubmitDish', id)
       },
    },
	}
</script>

<template>
  <div>
    <FormWizardCreateDishe
      v-if="recordinfnutri && recordingredient && recordnamedishe"
      :id="id"
      :dishe="true"
      :recordnamedishe="recordnamedishe"
      :recordingredient="recordingredient"
      :recordinfnutri="recordinfnutri"
      :edit-dish="true"
      @IsBack="isBack"
      @SubmitDish="SubmitDish"
    />
  </div>
</template>

<style scoped lang='scss'>

</style>

<template>
  <div>
    <b-row>
      <b-col cols="6">
        <b-form-group
          label="Nombre del plato*"
          label-for="name"
          :class="{ 'has-error': $v.formData.name.$error }"
        >
          <input
            id="name"
            v-model="formData.name"
            class="form-control"
            type="text"
            placeholder="Escriba el nombre del plato"
            @blur="$v.formData.name.$touch()"
            @input="formatInput"
          >
          <span
            v-if="$v.formData.name.$error && !$v.formData.name.required"
            class="help-block text-danger"
          >Campo requerido</span>

          <span
            v-if="$v.formData.name.$error && !$v.formData.name.isDiffEditDishName"
            class="help-block text-danger"
          >
            El nombre del plato ya existe
          </span>
        </b-form-group>
      </b-col>
      <b-col
        v-if="!dishe"
        cols="6"
      >
        <b-form-group
          label="Tipo de acompañamiento*"
          label-for="type_accompaniment"
          :class="{ 'has-error': $v.formData.type_accompaniment.$error }"
        >
          <b-form-select
            v-model="formData.type_accompaniment"
            :options="options"
            :reduce="option => option.value"
            @blur="$v.formData.type_accompaniment.$touch()"
          />
          <span
            v-if="$v.formData.type_accompaniment.$error && !$v.formData.type_accompaniment.required"
            class="help-block text-danger"
          >Campo requerido</span>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label="Subir Foto*"
          label-for="image"
        >
          <div class="image__preview mb-1">
            <template v-if="!photo">
              <img src="@/assets/images/default.png">
            </template>
            <template v-else>
              <img :src="photo">
              <button
                class="btn__delete"
                @click="RemovePhoto"
              >
                <feather-icon icon="Trash2Icon" />
              </button>
            </template>
          </div>
          <div>
            <b-button
              type="button"
              variant="primary"
              @click="$refs.fileInput.click()"
            >
              <feather-icon icon="CameraIcon" />
              Subir foto
              <input
                id="file"
                ref="fileInput"
                type="file"
                class="d-none"
                name="file0"
                accept=".jpg, .png, .jpeg"
                @change="fileChange"
              >
            </b-button>
          </div>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
    BFormGroup,
    BButton,
    BCol,
    BFormSelect,
    BRow,
  } from 'bootstrap-vue'
import { required } from 'vuelidate/lib/validators'
import { API_ROUTES } from '../../config/routes/api'

export default {
  components: {
    BFormGroup,
    BButton,
    BCol,
    BRow,
    BFormSelect,
  },
  props: {
    record: {
      type: Object,
      default: null,
    },
    dishe: {
      type: Boolean,
      default: null,
    },
    editDish: {
      type: Boolean,
      default: false,
    },
    editDishName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      formData: {
        name: '',
        image: '',
        type_accompaniment: null,
        accompaniment: !this.dishe,
      },
      photo: '',
      options: [
        { value: null, text: 'Seleccione un tipo' },
        { value: 1, text: 'Bebidas frìas' },
        { value: 2, text: 'Bebidas calientes' },
      ],
    }
  },
  watch: {
    record(newData) {
      this.fillFormData(newData)
    },
  },
  mounted() {
    const me = this
    me.fillFormData()
  },
  methods: {
    formatInput() {
      const me = this
      const text = me.formData.name.replace(/[^a-zA-Z0-9\s]/g, '')

      me.formData.name = text
    },
    fileChange(e) {
      const me = this
      // Recoger el fichero
      const file = e.target.files[0]
      me.formData.image = file
      const { type } = file
      if (type != 'image/jpeg' && type != 'image/jpg' && type != 'image/png') {
        me.$bvToast.toast('¡Formato no permitido!', {
					title: 'Los formatos permitidos son .jpg .jpeg .png',
					toaster: 'b-toaster-bottom-right',
					variant: 'danger',
					solid: true,
					appendToast: true,
				})
        me.RemovePhoto()
      } else {
        const reader = new FileReader()
        reader.onload = () => {
          me.photo = reader.result
        }
        reader.readAsDataURL(file)
      }
    },
    RemovePhoto() {
      this.formData.image = ''
      this.photo = ''
    },
    validate() {
      const me = this
      me.$v.formData.$touch()
      const isValid = !me.$v.formData.$invalid

      me.$emit('on-validate', me.formData, isValid)
      return isValid
    },
    fillFormData() {
      const me = this
      me.formData = { ...me.formData, ...me.record }
      const img = me.formData.image !== '' ? me.photo = API_ROUTES.images.image + me.formData.image : me.photo = ''
    },
  },
  validations() {
    const me = this

    const isDiffEditDishName = value => value.trim().toLowerCase() !== me.editDishName.trim().toLowerCase()

    let validationDefault = {
      name: {
        required,
      },
    }
    if (me.editDish) {
        validationDefault = {
        name: {
          required,
          isDiffEditDishName,
        },
      }
    }

    return me.dishe ? {
      formData: {
        ...validationDefault,
      },
    } : {
      formData: {
        type_accompaniment: {
          required,
        },
        ...validationDefault,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.imagen
{
	border: 1px solid #808080;
	width: 200px;
	height: 200px;
	border-radius: .3rem;
	background-color: #fff;
	cursor: pointer;
}

.image__preview img
{
	width: 310px;
	height: 250px;
}

.btn__delete
{
	background-color: #7367F0;
	color: #fff;
	font-size: 16px;
	padding: 3px 8px 3px 8px;
	border-radius: 50%;
  border: none;
	letter-spacing: 0.5px;
	position: absolute;
}

</style>

<template>
  <div class="mb-4">
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      layout="vertical"
      class="wizard-vertical mb-3 "
      :class="{'card__': !editDish}"
    >
      <!-- nombre del plato tab -->
      <tab-content
        title="Nombre del plato"
        :before-change="() => validateStep('step1')"
      >
        <name-dishe-data
          ref="step1"
          :dishe="dishe"
          :record="recordnamedishe"
          :edit-dish="editDish"
          :edit-dish-name="editDishName"
          @on-validate="nameDisheData"
        />
      </tab-content>

      <!-- Ingredientes tab -->
      <tab-content
        title="Ingredientes"
        :before-change="() => validateStep('step2')"
      >
        <ingredient-data
          ref="step2"
          :recordingredient="recordingredient"
          @on-validate="ingredientData"
        />
      </tab-content>

      <!-- Info nutricional tab -->
      <tab-content
        title="Info Nutricional"
        :before-change="() => validateStep('step3')"
      >
        <nutritional-info-data
          ref="step3"
          :recordinfnutri="recordinfnutri"
          @on-validate="infoNutriData"
        />
      </tab-content>
      <template
        slot="footer"
        scope="props"
      >
        <div class="wizard-footer-left mr-1">
          <wizard-button
            v-if="editDish"
            :style="props.fillButtonStyle"
            @click.native="back"
          >
            Salir
          </wizard-button>
        </div>

        <div class="wizard-footer-left">
          <wizard-button
            v-if="props.activeTabIndex > 0 && !props.isLastStep"
            :style="props.fillButtonStyle"
            @click.native="props.prevTab()"
          >
            Atrás
          </wizard-button>
        </div>
        <div class="wizard-footer-right">
          <wizard-button
            v-if="!props.isLastStep"
            class="wizard-footer-right"
            :style="props.fillButtonStyle"
            @click.native="props.nextTab()"
          >
            Siguiente
          </wizard-button>

          <wizard-button
            v-else
            class="wizard-footer-right finish-button"
            :style="props.fillButtonStyle"
            @click.native="validateStep('step3')"
          >
            <b-spinner
              v-if="isBusy"
              small
            />
            <v-icon
              v-else
              name="save"
            />
            {{ props.isLastStep ? 'Guardar' : 'Siguiente' }}
          </wizard-button>
        </div>
      </template>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { BSpinner } from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import NameDisheData from './addForms/NameDisheData'
import IngredientData from './addForms/IngredientData'
import NutritionalInfoData from './addForms/NutritionalInfoData'
import { API_ROUTES } from '@/modules/diet_management_nutritionist/config/routes/api'

export default {
	inject: ['disheRepository', 'nutritionalInfoRepository'],
	components: {
		FormWizard,
		TabContent,
		NameDisheData,
		IngredientData,
		NutritionalInfoData,
		WizardButton,
		BSpinner,
	},
	props: {
		dishe: {
			type: Boolean,
			default: null,
		},
    editDish: {
      type: Boolean,
      default: false,
    },
		id: {
			type: String,
			default: null,
		},
		recordnamedishe: {
			type: Object,
			default: null,
		},
		recordingredient: {
			type: Array,
			default: null,
		},
		recordinfnutri: {
			type: Array,
			default: null,
		},
	},
	data() {
		return {
			formData: {},
			formData2: {},
			repository: 'disheRepository',
			isBusy: false,
			infoNutritional: {},
      editDishName: '',
		}
	},
  created() {
    if (this.editDish) {
      console.log('this.editDish', this.editDish)
      console.log(this.recordnamedishe)
      this.editDishName = this.recordnamedishe.name
    }
  },
	methods: {
    back() {
      this.$emit('IsBack', false)
    },
		validateStep(name) {
			const refToValidate = this.$refs[name]
			return refToValidate.validate()
		},
		nameDisheData(model, isValid) {
			if (isValid) {
				this.formData2 = { ...this.formData2, ...model }
			}
		},
		ingredientData(model, isValid) {
			if (isValid) {
				const me = this
				me.formData2.ingredients = model

				me.formData = new FormData()
				me.formData.append('name', me.formData2.name)
				me.formData.append('image', me.formData2.image)
				if (!me.dishe) {
          me.formData.append(
						'type_accompaniment',
						me.formData2.type_accompaniment,
					)
        }
				me.formData.append('accompaniment', me.formData2.accompaniment)
				me.formData.append('ingredients', me.formData2.ingredients)
			}
		},
		async infoNutriData(model, isValid) {
			if (isValid) {
				const me = this
				await me.loadTypeDiets()
				me.infoNutritional.nutritional_values = model
				this.formSubmitted()
			}
		},
		async formSubmitted() {
			const me = this
			try {
				me.isBusy = true
				let uuid = me.id ? me.id : uuidv4()
        let option = me.id ? 'update' : 'save'
        if (me.editDish) {
          uuid = uuidv4()
          option = 'save'
          me.formData.append('variant', me.id)
        }
        console.log('me.formData', me.formData.get('image'))

				const res = await me.disheRepository[option](uuid, me.formData)
				const { id } = res.data
				const info = await me.nutritionalInfoRepository.save(
					id,
					me.infoNutritional,
				)

				me.$bvToast.toast('Registro guardado con éxito', {
					title: 'Éxito',
					toaster: 'b-toaster-bottom-right',
					variant: 'success',
					solid: true,
					appendToast: true,
				})

        if (me.editDish) {
          me.$emit('SubmitDish', id)

          return
        }

				const redirect = !me.dishe
					? me.$router.push({ name: 'list-accompaniment' })
					: me.$router.push({ name: 'list-dishe' })
			} catch (err) {
				const { errors, message } = err.response.data
				if (errors && errors.name) {
					me.$bvToast.toast('El nombre del plato ya existe.', {
						title: 'Ocurrió un error al guardar el registro',
						toaster: 'b-toaster-bottom-right',
						variant: 'danger',
						solid: true,
						appendToast: true,
					})
				} else {
					me.$bvToast.toast(message, {
						title: 'Ocurrió un error al guardar el registro',
						toaster: 'b-toaster-bottom-right',
						variant: 'danger',
						solid: true,
						appendToast: true,
					})
				}
			} finally {
				me.isBusy = false
			}
		},
		isLastStep() {
			if (this.$refs.wizard) {
				return this.$refs.wizard.isLastStep
			}
			return false
		},
		async loadTypeDiets() {
			const me = this
			try {
				const res = await me.nutritionalInfoRepository.getAllByTypeDiet()
				me.infoNutritional.diet_type_id = res.data[0].id
			} catch (error) {
				// console.log(error)
			}
		},
	},
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.card__ {
	box-shadow: 0 5px 11px 0 rgba(145, 144, 144, 0.3),
		0 4px 15px 0 rgba(133, 132, 132, 0.15);
}
</style>

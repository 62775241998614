<template>
  <b-modal
    id="modal-program-menu"
    centered
    :title="title"
    :size="editDish ? 'xl' : 'lg'"
    no-close-on-backdrop
    hide-footer
    @show="show"
  >
    <b-row>
      <b-col
        v-if="day"
        cols="12"
      >
        <div class="d-flex justify-content-end">
          <v-icon
            name="calendar-alt"
            scale="1"
          />
          <span class="title ml-1">{{ day }}</span>
        </div>
      </b-col>
      <b-col cols="12">
        <b-tabs
          pills
          vertical
        >
          <b-tab
            title="Desayuno"
            :active="isActiveBreakfast"
            :disabled="isBreakfast"
          >
            <breakfast-form
              :is-busy="isBusy"
              :dishes="dishes"
              :day="day"
              :cold-drinks="coldDrinks"
              :hot-drinks="hotDrinks"
              :breakfast="breakfast"
              :dates-breakfast="datesBreakfast"
              :buttontitle="buttontitle"
              :is-detail="isDetail"
              @formSubmitted="formSubmitted"
              @IsBack="IsBack"
            />
          </b-tab>
          <b-tab
            title="Almuerzo"
            :disabled="isLuch"
          >
            <luch-form
              :is-busy="isBusy"
              :dishes="dishes"
              :day="day"
              :cold-drinks="coldDrinks"
              :hot-drinks="hotDrinks"
              :lunch="lunch"
              :dates-luch="datesLuch"
              :buttontitle="buttontitle"
              :is-detail="isDetail"
              @formSubmitted="formSubmitted"
              @IsBack="IsBack"
            />
          </b-tab>
          <b-tab
            title="Cena"
            :disabled="isDinner"
          >
            <dinner-form
              :is-busy="isBusy"
              :dishes="dishes"
              :day="day"
              :cold-drinks="coldDrinks"
              :hot-drinks="hotDrinks"
              :dinner="dinner"
              :dates-dinner="datesDinner"
              :buttontitle="buttontitle"
              :is-detail="isDetail"
              @formSubmitted="formSubmitted"
              @IsBack="IsBack"
            />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
  BRow,
  BCol,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { v4 as uuidv4 } from 'uuid'
import BreakfastForm from '../program_menu/addForm/BreakfastForm'
import LuchForm from '../program_menu/addForm/LuchForm'
import DinnerForm from '../program_menu/addForm/DinnerForm'

export default {
  inject: ['menuRepository'],
  components: {
    BModal,
    BRow,
    BCol,
    BTabs,
    BTab,
    BreakfastForm,
    LuchForm,
    DinnerForm,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    day: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    buttontitle: {
      type: String,
      default: null,
    },
    breakfasts: {
      type: Object,
      default: null,
    },
    luches: {
      type: Object,
      default: null,
    },
    dinners: {
      type: Object,
      default: null,
    },
    dishes: {
      type: Array,
      default: null,
    },
    coldDrinks: {
      type: Array,
      default: null,
    },
    hotDrinks: {
      type: Array,
      default: null,
    },
    isDetail: {
      type: Boolean,
      default: null,
    },
    datesBreakfast: {
      type: Array,
      default: null,
    },
    datesLuch: {
      type: Array,
      default: null,
    },
    datesDinner: {
      type: Array,
      default: null,
    },
  },
  data() {
    const me = this
    return {
      isBusy: false,
      isActiveBreakfast: true,
      isBreakfast: false,
      isLuch: false,
      isDinner: false,
      breakfast: {
        dishes: [],
        hotDrinks: [],
        coldDrinks: [],
      },
      lunch: {
        dishes: [],
        hotDrinks: [],
        coldDrinks: [],
      },
      dinner: {
        dishes: [],
        hotDrinks: [],
        coldDrinks: [],
      },
      editDish: false,
    }
  },
  watch: {
    breakfasts(newData) {
      this.fillData(newData, '1')
    },
    luches(newData) {
      this.fillData(newData, '2')
    },
    dinners(newData) {
      this.fillData(newData, '3')
    },
  },
  methods: {
    IsBack(edit) {
      console.log('is back', edit)
      this.editDish = edit
    },
    async formSubmitted(data) {
      const me = this
      try {
        me.isBusy = true
        let uuid = null
        let option = null

        if (data.type_menu == '1' && me.breakfasts) {
          uuid = me.breakfasts.id
          option = 'update'
        } else if (data.type_menu == '2' && me.luches) {
          uuid = me.luches.id
          option = 'update'
        } else if (data.type_menu == '3' && me.dinners) {
          uuid = me.dinners.id
          option = 'update'
        } else {
          uuid = uuidv4()
          option = 'save'
        }

        const res = await me.menuRepository[option](uuid, { ...data, date: me.day })
        if (data.type_menu == '1') {
          if (option == 'save') me.isBreakfast = true
          me.$emit('refreshMenus', option)
        } else if (data.type_menu == '2') {
          if (option == 'save') me.isLuch = true
          me.$emit('refreshMenus', option)
        } else {
          if (option == 'save') me.isDinner = true
          me.$emit('refreshMenus', option)
        }

        if (me.isBreakfast && me.isLuch && me.isDinner) me.$bvModal.hide('modal-program-menu')

        const message = option == 'save' ? 'Registro guardado con éxito' : 'Registro actualizado con éxito'
        me.$bvToast.toast(message, {
					title: 'Éxito',
					toaster: 'b-toaster-bottom-right',
					variant: 'success',
					solid: true,
					appendToast: true,
				})
      } catch (error) {
        const { message } = error.response.data
        me.$bvToast.toast(message, {
					title: 'Ocurrió un error al guardar el registro',
					toaster: 'b-toaster-bottom-right',
					variant: 'danger',
					solid: true,
					appendToast: true,
				})
      } finally {
        me.isBusy = false
      }
    },
    show() {
      const me = this

      me.isBreakfast = false
      me.isLuch = false
      me.isDinner = false
      me.isActiveBreakfast = true
    },
    fillData(newData, type) {
      const me = this
      let dishesData = []
      let hotDrinksData = []
      let coldDrinksData = []

      if (newData) {
        newData.items.forEach(x => {
          if (x.accompaniment == '0') {
            dishesData.push(x)
          } else if (x.type_accompaniment !== null && x.type_accompaniment == '1') {
              coldDrinksData.push(x)
            } else {
              hotDrinksData.push(x)
            }
        })
      } else {
        dishesData = []
        hotDrinksData = []
        coldDrinksData = []
      }

      const d = dishesData.length > 0 ? dishesData : []
      const hd = hotDrinksData.length > 0 ? hotDrinksData : []
      const cd = coldDrinksData.length > 0 ? coldDrinksData : []

      if (type == '1') {
        me.breakfast.dishes = d
        me.breakfast.hotDrinks = hd
        me.breakfast.coldDrinks = cd
      } else if (type == '2') {
        me.lunch.dishes = d
        me.lunch.hotDrinks = hd
        me.lunch.coldDrinks = cd
      } else {
        me.dinner.dishes = d
        me.dinner.hotDrinks = hd
        me.dinner.coldDrinks = cd
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  color: #000;
  font-weight: 700;
}

.title2 {
  font-size: 18px;
  color: #000;
  font-weight: 700;
}

.card__ {
  box-shadow: 0 5px 11px 0 rgba(100, 100, 100, 0.3), 0 4px 15px 0 rgba(77, 76, 76, 0.15) !important;
  height: 210px;
}

.img__ {
  height: 137px;
}

.padding__ {
  padding: 8px;
}
</style>

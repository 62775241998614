<template>
  <b-modal
    id="modal-create-protein"
    centered
    title="Nueva proteìna"
  >
    <b-row>
      <b-col cols="12">
        <b-form-group
          label="Nombre*"
          label-for="name"
          :class="{ 'has-error': $v.formData.name.$error }"
        >
          <b-form-input
            id="name"
            v-model="formData.name"
            placeholder="Escriba el nombre de la proteìna"
            @blur="$v.formData.name.$touch()"
          />
          <span
            v-if="$v.formData.name.$error && !$v.formData.name.required"
            class="help-block text-danger"
          >Campo requerido</span>
        </b-form-group>
      </b-col>
    </b-row>
    <template #modal-footer="{ close }">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        v-b-modal.modal-seconday
        variant="outline-secondary"
        @click="close()"
      >
        Cerrar
      </b-button>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="success"
        :disabled="isBusy || $v.$invalid"
      >
        <b-spinner
          v-if="isBusy"
          small
        />
        <v-icon
          v-else
          name="save"
        />
        Guardar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
  BButton,
  BFormGroup,
  BCol,
  BFormInput,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    BModal,
    BButton,
    BFormGroup,
    BCol,
    BFormInput,
    BRow,
    BSpinner,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      formData: {
        name: '',
      },
      isBusy: false,
    }
  },
  validations: {
    formData: {
      name: {
        required,
      },
    },
  },
}
</script>

<style lang="scss"></style>

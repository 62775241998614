<template>
  <div>
    <Statistics
      :series="series"
      :statistics="statistics"
      :is-busy="isBusy3"
    />
    <b-row class="mb-1">
      <b-col cols="3">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          block
          variant="primary"
          @click="redirect('create-dishe')"
        >
          Crear plato
        </b-button>
      </b-col>
      <b-col cols="3">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          block
          variant="primary"
          @click="redirect('create-accompaniment')"
        >
          Crear Acompañamiento
        </b-button>
      </b-col>
      <b-col cols="3">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          block
          variant="primary"
          @click="redirect('program-menu')"
        >
          Planear menú
        </b-button>
      </b-col>
      <b-col cols="3">
        <b-form-group>
          <b-form-radio-group
            v-model="selectedRadio"
            button-variant="outline-primary"
            :options="optionsRadio"
            buttons
            @input="filterBy"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col
        v-if="day"
        cols="12"
      >

        <div class="title p-1">
          MENÚ DEL DÍA
        </div>
        <div v-if="isBussy">
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-50" />
            <strong>Cargando...</strong>
          </div>
        </div>
        <Day
          v-else
          :menu-day="menuDay"
        />
      </b-col>
      <b-col
        v-if="month"
        cols="12"
      >
        <Month
          :events="events"
          @detailOrEdit="detailOrEdit"
        />
      </b-col>
    </b-row>

    <program-menu-modal
      :day="date"
      :breakfasts="breakfasts"
      :luches="luches"
      :dinners="dinners"
      :title="title"
      :is-detail="isDetail"
    />
  </div>
</template>

<script>
import {
	BRow,
	BCol,
	BButton,
  BFormRadioGroup,
  BFormGroup,
  BSpinner,
} from 'bootstrap-vue'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import Day from '../components/Day'
import Month from '../components/Month'
import ProgramMenuModal from '../components/ProgramMenuModal'
import Statistics from '../components/Statistics'

export default {
  inject: ['menuRepository', 'orderRepository'],
  components: {
		BRow,
		BCol,
    BButton,
    BFormRadioGroup,
    BFormGroup,
    BSpinner,
    Day,
    Month,
    ProgramMenuModal,
    Statistics,
	},
  directives: {
    Ripple,
  },
  data() {
    return {
      series: [
				{
					name: 'Hoy',
					data: [],
				},
				{
					name: 'Ayer',
					data: [],
				},
			],
      selectedRadio: 1,
      optionsRadio: [
        { text: 'Día', value: 1 },
        { text: 'Mes', value: 2 },
      ],
      day: true,
      month: false,
      isBussy: false,
      isBussy2: false,
      isBusy3: false,
      menuDay: [],
      events: [],
      date: '',
      title: '',
      breakfasts: null,
      luches: null,
      dinners: null,
      isDetail: true,
      current_day: (moment(), moment()).format('YYYY-MM-DD'),
			yesterday: (moment().subtract(1, 'days'),
			moment().subtract(1, 'days')).format('YYYY-MM-DD'),
      statistics: [
        {
          img: require('@/assets/images/pendiente.png'),
          title: 'Pendientes',
          color: 'warning',
          breakfasts: [],
          lunches: [],
          dinners: [],
        },
        {
          img: require('@/assets/images/entregado.png'),
          title: 'Entregados',
          color: 'success',
          breakfasts: [],
          lunches: [],
          dinners: [],
        },
        // {
        //   img: require('@/assets/images/No_entregados.png'),
        //   title: 'No entregados',
        //   color: 'danger',
        //   breakfasts: [],
        //   lunches: [],
        //   dinners: [],
        // },
      ],
    }
  },
  async mounted() {
    const me = this
    await me.loadOrdersCurrentDay()
		await me.loadOrdersYesterday()
    await me.loadMenuByDate()
    await me.loadMenus()
    this.filterBy()
  },
  methods: {
    filterBy() {
      const me = this
      if (me.selectedRadio == 1) {
        me.day = true
        me.month = false
      } else {
        me.day = false
        me.month = true
      }
    },
    redirect(link) {
      const me = this
      me.$router.push({ name: link })
    },
    async loadMenuByDate() {
      const me = this
      try {
        me.isBussy = true
        me.menuDay = []
        const res = await me.menuRepository.getAllMenuByDate(me.current_day)
        const { data } = res
        data.sort((a, b) => {
          if (a.type_menu > b.type_menu) {
            return 1
          }
          if (a.type_menu < b.type_menu) {
            return -1
          }
          return 0
        })
        data.forEach(el => {
          if (el.type_menu == '1') {
            me.addItemMenuDay(el, 'Desayuno')
          } else if (el.type_menu == '2') {
            me.addItemMenuDay(el, 'Almuerzo')
          } else {
            me.addItemMenuDay(el, 'Cena')
          }
        })
      } catch (error) {
        me.menuDay = null
        // console.log(error)
      } finally {
        me.isBussy = false
      }
    },
    addItemMenuDay(data, type) {
      const me = this
      let dishes = []
      let hotDrinks = []
      let coldDrinks = []
      if (data) {
        data.items.forEach(x => {
          if (x.accompaniment == '0') {
            dishes.push(x)
          } else if (x.type_accompaniment !== null && x.type_accompaniment == '1') {
              coldDrinks.push(x)
            } else {
              hotDrinks.push(x)
            }
        })
      } else {
        dishes = null
        hotDrinks = null
        coldDrinks = null
      }

      const item = {
        title: type,
        dishes,
        hotDrinks,
        coldDrinks,
      }

      me.menuDay.push(item)
    },
    async loadMenus() {
      const me = this
      try {
        me.isBussy2 = true
        const menus = []
        me.events = []
        const res = await me.menuRepository.getAll()
        const { data } = res

        data.forEach(el => {
          const menu = menus.find(x => x.date == el.date)

          if (menu) {
            menu.menus.push(
              {
                id: el.id,
                type_menu: el.type_menu,
                items: el.items,
              },
            )
          } else {
            menus.push({
              date: el.date,
              menus: [
                {
                  id: el.id,
                  type_menu: el.type_menu,
                  items: el.items,
                },
              ],
            })
          }
        })

        menus.forEach(el => {
          me.events.push(
            {
              title: 'Ver detalle',
              date: el.date,
              backgroundColor: '#28c76f',
              borderColor: '#28c76f',
              textColor: '#fff',
              extendedProps: el,
              id: 2,
            },
          )
        })
      } catch (error) {
        // console.log(error)
      } finally {
        me.isBussy2 = false
      }
    },
    detailOrEdit(id, data) {
      const me = this
      me.breakfasts = null
      me.luches = null
      me.dinners = null
      data.menus.forEach(el => {
        if (el.type_menu == '1') {
          me.breakfasts = { id: el.id, items: el.items }
        } else if (el.type_menu == '2') {
          me.luches = { id: el.id, items: el.items }
        } else {
          me.dinners = { id: el.id, items: el.items }
        }
      })

      me.isDetail = true
      me.title = 'Detalle Menú'

			me.date = data.date
      me.$bvModal.show('modal-program-menu')
		},
    async loadOrdersCurrentDay() {
			const me = this
			try {
				const res = await me.orderRepository.getAllByDate(me.current_day)
				const { data } = res
				me.fillMenuDay(data)
        me.fillOrderStatistics(data)
			} catch (error) {
				me.series[0].data = []
				// console.log(error)
			}
		},
		async loadOrdersYesterday() {
			const me = this
			try {
				me.isBusy3 = true
				const res = await me.orderRepository.getAllByDate(me.yesterday)
				const { data } = res
				me.fillMenuYesterday(data)
			} catch (error) {
        me.series[1].data = []
				// console.log(error)
			} finally {
				me.isBusy3 = false
			}
		},
    async fillMenuDay(data) {
			const me = this
			const luches = []
			const breakfast = []
			const dinners = []
			if (data) {
				data.forEach(el => {
					if (el.menu.type_menu == '1') {
						breakfast.push(el)
					} else if (el.menu.type_menu == '2') {
						luches.push(el)
					} else {
						dinners.push(el)
					}
				})
				me.series[0].data.push(breakfast.length, luches.length, dinners.length)
			}
		},
		async fillMenuYesterday(data) {
			const me = this
			const luchesYesterday = []
			const breakfastYesterday = []
			const dinnersYesterday = []
			if (data) {
				data.forEach(el => {
					if (el.menu.type_menu == '1') {
						breakfastYesterday.push(el)
					} else if (el.menu.type_menu == '2') {
						luchesYesterday.push(el)
					} else {
						dinnersYesterday.push(el)
					}
				})

				me.series[1].data.push(
					breakfastYesterday.length,
					luchesYesterday.length,
					dinnersYesterday.length,
				)
			}
		},
    fillOrderStatistics(data) {
      const me = this
      if (data) {
				data.forEach(el => {
          /* Pendientes */
          if (el.delivery_person == '') {
            if (el.menu.type_menu == '1') {
              me.statistics[0].breakfasts.push(el)
            } else if (el.menu.type_menu == '2') {
              me.statistics[0].lunches.push(el)
            } else {
              me.statistics[0].dinners.push(el)
            }
          }
          /* Entregados */
          if (el.arrival_at !== '') {
            if (el.menu.type_menu == '1') {
              me.statistics[1].breakfasts.push(el)
            } else if (el.menu.type_menu == '2') {
              me.statistics[1].lunches.push(el)
            } else {
              me.statistics[1].dinners.push(el)
            }
          }
				})
			}
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 18px;
  font-weight: 700;
  color: #636363;
}
</style>

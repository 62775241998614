<template>
  <div>
    <transition
      mode="out-in"
      name="fade"
    >
      <div v-if="!editDish">
        <b-row>
          <b-col
            v-if="!day"
            lg="12"
            md="12"
            sm="12"
            class="mb-1"
          >
            <date-picker
              v-model="rangeDates"
              is-range
              :attributes="attrs"
              color="blue"
              mode="date"
              :model-config="modelConfig"
              :disabled-dates="datesBreakfast"
              :columns="$screens({ default: 1, lg: 2 })"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <b-row>
                  <b-col
                    lg="6"
                    md="6"
                    sm="12"
                  >
                    <div role="group">
                      <label
                        class="title"
                      >Fecha inicial*</label>
                      <b-form-input
                        :value="inputValue.start"
                        v-on="inputEvents.start"
                      />
                    </div>
                  </b-col>
                  <b-col
                    lg="6"
                    md="6"
                    sm="12"
                  >
                    <div role="group">
                      <label
                        class="title"
                      >Fecha final*</label>
                      <b-form-input
                        :value="inputValue.end"
                        v-on="inputEvents.end"
                      />
                    </div>
                  </b-col>
                </b-row>
              </template>
            </date-picker>
          </b-col>
          <b-col cols="12">
            <div class="title">
              Platos Principales
            </div>
          </b-col>
          <b-col
            v-if="!isDetail"
            cols="12"
          >
            <b-form-group>
              <v-select
                v-model="dishesData"
                multiple
                :options="dishes"
                label="name"
                placeholder="Seleccione platos"
                @input="setSelected"
              >
                <template #option="{ name, image }">
                  <template v-if="image !== null">
                    <img
                      :src="loadImage(image)"
                      :alt="name"
                      width="10%"
                      height="30px"
                    >
                  </template>
                  <template v-else>
                    <img
                      src="@/assets/images/default.png"
                      :alt="name"
                      width="10%"
                    >
                  </template>
                  <span> {{ name }}</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <template v-if="dishesData">
            <b-col
              v-for="(item, index) in dishesData"
              :key="index"
              cols="3"
              class="wrapper"
            >
              <card-dishe
                :item="item"
                class="image-box"
              />

              <button
                class="edit-box btn btn-primary btn-sm"
                @click="editDishMethod(item)"
              >
                <i class="fa-solid fa-pen-to-square" />
              </button>

            </b-col>
          </template>
          <template v-else>
            <b-col
              v-if="isDetail"
              cols="12"
              class="d-flex justify-content-center"
            >
              <div class="title2">
                No existen registros
              </div>
            </b-col>
          </template>
        </b-row>
        <b-row>
          <b-col cols="12">
            <div class="title">
              Bebidas Calientes
            </div>
          </b-col>
          <b-col
            v-if="!isDetail"
            cols="12"
          >
            <b-form-group>
              <v-select
                v-model="hotDrinksData"
                multiple
                :options="hotDrinks"
                label="name"
                placeholder="Seleccione bebidas calientes"
                @input="setSelected"
              >
                <template #option="{ name, image }">
                  <template v-if="image !== null">
                    <img
                      :src="loadImage(image)"
                      :alt="name"
                      width="10%"
                      height="30px"
                    >
                  </template>
                  <template v-else>
                    <img
                      src="@/assets/images/default.png"
                      :alt="name"
                      width="10%"
                    >
                  </template>
                  <span> {{ name }}</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <template v-if="hotDrinksData">
            <b-col
              v-for="(item, index) in hotDrinksData"
              :key="index"
              cols="3"
            >
              <card-dishe :item="item" />
            </b-col>
          </template>
          <template v-else>
            <b-col
              v-if="isDetail"
              cols="12"
              class="d-flex justify-content-center"
            >
              <div class="title2">
                No existen registros
              </div>
            </b-col>
          </template>
        </b-row>
        <b-row>
          <b-col cols="12">
            <div class="title">
              Bebidas Frías
            </div>
          </b-col>
          <b-col
            v-if="!isDetail"
            cols="12"
          >
            <b-form-group>
              <v-select
                v-model="coldDrinksData"
                multiple
                :options="coldDrinks"
                label="name"
                placeholder="Seleccione bebidas frías"
                @input="setSelected"
              >
                <template #option="{ name, image }">
                  <template v-if="image !== null">
                    <img
                      :src="loadImage(image)"
                      :alt="name"
                      width="10%"
                      height="30px"
                    >
                  </template>
                  <template v-else>
                    <img
                      src="@/assets/images/default.png"
                      :alt="name"
                      width="10%"
                    >
                  </template>
                  <span> {{ name }}</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <template v-if="coldDrinksData">
            <b-col
              v-for="(item, index) in coldDrinksData"
              :key="index"
              cols="3"
            >
              <card-dishe :item="item" />
            </b-col>
          </template>
          <template v-else>
            <b-col
              v-if="isDetail"
              cols="12"
              class="d-flex justify-content-center"
            >
              <div class="title2">
                No existen registros
              </div>
            </b-col>
          </template>
        </b-row>
        <b-row>
          <b-col
            v-if="!isDetail"
            cols="12"
            class="text-center mt-1 mb-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              :disabled="isBusy || $v.$invalid"
              @click="save"
            >
              <b-spinner
                v-if="isBusy"
                small
              />
              <v-icon
                v-else
                name="save"
              />
              {{ buttontitle }}
            </b-button>
          </b-col>
        </b-row>
      </div>
      <NewDishProgramarMenu
        v-if="editDish"
        :id="editDishId"
        @IsBack="isBack"
        @SubmitDish="submitDish"
      />
    </transition>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BSpinner,
  BButton,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { required } from 'vuelidate/lib/validators'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import CardDishe from '../../components/CardDishe'
import { API_ROUTES } from '../../config/routes/api'
import NewDishProgramarMenu from '@/modules/diet_management_nutritionist/components/addForms/NewDishProgramarMenu.vue'
import CreateDish from '@/modules/diet_management_nutritionist/list_of_dishes/CreateDishe.vue'

export default {
  inject: ['disheRepository', 'menuRepository', 'accompanimentRepository'],
  components: {
    BRow,
    BCol,
    BFormGroup,
    BSpinner,
    BButton,
    BFormInput,
    vSelect,
    CardDishe,
    DatePicker,
    NewDishProgramarMenu,
  },
  directives: {
    Ripple,
  },
  props: {
    isBusy: {
      type: Boolean,
      default: null,
    },
    isDetail: {
      type: Boolean,
      default: null,
    },
    coldDrinks: {
      type: Array,
      default: null,
    },
    hotDrinks: {
      type: Array,
      default: null,
    },
    breakfast: {
      type: Object,
      default: null,
    },
    buttontitle: {
      type: String,
      default: null,
    },
    day: {
      type: String,
      default: null,
    },
    datesBreakfast: {
      type: Array,
      default: null,
    },
  },
  data() {
    const me = this
    return {
      dishesItems: me.dishes,
      coldDrinkItems: me.coldDrinks,
      hotDrinkItems: me.hotDrinks,
      dishesData: me.breakfast.dishes,
      hotDrinksData: me.breakfast.hotDrinks,
      coldDrinksData: me.breakfast.coldDrinks,
      menu_items: [],
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      attrs: [
        {
          highlight: 'green',
          dates: new Date(),
        },
      ],
      rangeDates: {
        start: '',
        end: '',
      },
      editDish: false,
      editDishId: '',
      dishes: [],
    }
  },
  watch: {},
  validations() {
    const me = this
    const defaultValidation = {
      menu_items: {
        required,
      },
    }

    return me.day ? defaultValidation : {
      ...defaultValidation,
      rangeDates: {
        start: {
          required,
        },
        end: {
          required,
        },
      },
    }
  },
  created() {
    this.loadDishes()
  },
  methods: {
    async loadDishes() {
      const me = this
      try {
        const res = await me.disheRepository.getAll(1, 10000)
        me.dishes = res.data
      } catch (error) {
        // console.log(error)
      }
    },
    isBack(e) {
      this.$emit('IsBack', e)
      this.editDish = e
    },
    async submitDish(id) {
      const me = this
      await this.loadDishes()
      this.editDish = false

      if (id) {
        // remove dish
        const index = this.dishesData.findIndex(el => el.id === me.editDishId)
        this.dishesData.splice(index, 1)
        // add dish
        const dish = this.dishes.find(el => el.id === id)
        this.dishesData.push(dish)
        this.setSelected()
      }
      this.$emit('IsBack', false)
    },
    save() {
      const me = this
      const data = {
        type_menu: '1',
        menu_items: me.menu_items,
        start_date: me.rangeDates.start || me.day,
        end_date: me.rangeDates.end || me.day,
      }
      this.$emit('formSubmitted', data)
    },
    loadImage(image) {
      return API_ROUTES.images.image + image
    },
    setSelected() {
      const me = this
      me.menu_items = []
      if (me.dishesData.length > 0) me.dishesData.forEach(el => me.menu_items.push({ food_id: el.id }))
      if (me.hotDrinksData.length > 0) me.hotDrinksData.forEach(el => me.menu_items.push({ food_id: el.id }))
      if (me.coldDrinksData.length > 0) me.coldDrinksData.forEach(el => me.menu_items.push({ food_id: el.id }))

      if (me.dishesData.length > 0 || me.hotDrinksData.length > 0 || me.coldDrinksData.length > 0) {
        me.menu_items = JSON.stringify(me.menu_items)
      } else {
        me.menu_items = []
      }
    },
    editDishMethod(item) {
      console.log(item)
      const me = this
      me.editDishId = item.id

      me.editDish = true
    },
  },
}
</script>

<style lang="scss" scoped>
  .wrapper {
    position: relative;
  }

  .image-box {
    position: relative;
    z-index: 1;
  }
  .edit-box {
    position: absolute;
    z-index: 3;
    right: 20px;
    top: 3px;
  }

  .box {
    border: 1px solid #5E5878;
    border-radius: .6rem;
    height: 140px;
  }

  .title {
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 8px;
    color: #5E5878;
  }

  .title2 {
    font-size: 12px;
    margin-bottom: 5px;
    color: #5E5878;
  }

  .img__ {
    height: 90px !important;
    margin-bottom: 5px !important;
  }

  .box__ {
		border: 1.5px solid #7367F0;
		padding: 20px;
		color: #7367F0;
		font-weight: bold;
	}

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

</style>

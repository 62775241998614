<template>
  <div class="p-1">
    <b-row
      v-if="menuDay"
      class="mb-1"
    >
      <b-col
        v-for="(item, index) in menuDay"
        :key="index"
        cols="4"
      >
        <b-card no-body>
          <template #header>
            <div class="title">
              {{ item.title }}
            </div>
          </template>
          <b-row class="p-1">
            <b-col cols="12">
              <div class="title2 text-warning">
                Platos Principales
              </div>
            </b-col>
            <template v-if="item.dishes.length > 0">
              <b-col
                v-for="(dish) in item.dishes"
                :key="dish.id"
                cols="6"
              >
                <card-dishe :item="dish" />
              </b-col>
            </template>
            <template v-else>
              <b-col
                cols="12"
              >
                <div class="title2">
                  Sin registros para mostrar
                </div>
              </b-col>
            </template>
          </b-row>
          <b-row class="p-1">
            <b-col cols="12">
              <div class="title2 text-danger">
                Bebidas Calientes
              </div>
            </b-col>
            <template v-if="item.hotDrinks.length > 0">
              <b-col
                v-for="(hot) in item.hotDrinks"
                :key="hot.id"
                cols="6"
              >
                <card-dishe :item="hot" />
              </b-col>
            </template>
            <template v-else>
              <b-col
                cols="12"
              >
                <div class="title2">
                  Sin registros para mostrar
                </div>
              </b-col>
            </template>
          </b-row>
          <b-row class="p-1">
            <b-col cols="12">
              <div class="title2 text-info">
                Bebidas Frías
              </div>
            </b-col>
            <template v-if="item.coldDrinks.length > 0">
              <b-col
                v-for="(cold) in item.coldDrinks"
                :key="cold.id"
                cols="6"
              >
                <card-dishe :item="cold" />
              </b-col>
            </template>
            <template v-else>
              <b-col
                cols="12"
              >
                <div class="title2">
                  Sin registros para mostrar
                </div>
              </b-col>
            </template>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row
      v-else
    >
      <b-col
        cols="12"
        class="text-center title"
      >
        No hay menú programado.
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
	BCard,
	BRow,
	BCol,
} from 'bootstrap-vue'
import CardDishe from './CardDishe'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    CardDishe,
  },
  props: {
    menuDay: {
      type: Array,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
  .card__ {
    box-shadow: 0 5px 11px 0 rgba(145, 144, 144, 0.3), 0 4px 15px 0 rgba(133, 132, 132, 0.15);
  }

  .title {
    font-size: 18px;
    font-weight: 700;
    color: #636363;
  }

  .title2 {
    font-size: 14px;
    font-weight: 700;
    color: #636363;
    margin-bottom: 8px;
  }

  .card-header {
    background-color: rgb(236, 234, 234);
    padding: 8px;
    margin-bottom: 5px;
  }
</style>

<template>
  <div class="">
    <b-row>
      <b-col
        cols="12"
        class="mb-2"
      >
        <div class="d-flex">
          <div class="w-100">
            <v-select
              v-model="infoNutritionals"
              label="title"
              multiple
              :options="options"
              placeholder="Seleccione informaciòn nutricional"
            >
              <div slot="no-options">
                No hay opciones
              </div>
            </v-select>
            <span
              v-if="$v.infoNutritionals.$error && !$v.infoNutritionals.required"
              class="help-block text-danger"
            >Campo requerido</span>
          </div>
          <div>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-create-protein
              variant="primary"
              class="btn-icon ml-1"
            >
              <feather-icon icon="PlusIcon" />
            </b-button>
          </div>
        </div>
      </b-col>
      <b-col
        v-for="(item, index) in infoNutritionals"
        :key="index"
        cols="12"
      >
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="item.title"
          label-for="input-default"
        >
          <b-form-input
            id="input-default"
            v-model="item.value"
            type="number"
            placeholder="Cantidad"
            class="w-50"
          />
          <span
            v-if="$v.infoNutritionals.$each[index].value.$error && !$v.infoNutritionals.$each[index].value.required"
            class="help-block text-danger"
          >Campo requerido</span>
        </b-form-group>
      </b-col>
    </b-row>
    <new-protein-modal />
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { required } from 'vuelidate/lib/validators'
import NewProteinModal from '../NewProteinModal'

export default {
  components: {
    BCol,
    BRow,
    vSelect,
    BFormGroup,
    BFormInput,
    BButton,
    NewProteinModal,
  },
  directives: {
    Ripple,
  },
  props: {
    recordinfnutri: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      infoNutritionals: this.recordinfnutri,
			options: [
				{
          id: 1,
					title: 'Energìa (Kcal)',
          value: '',
          unit: '(Kcal)',
				},
				{
          id: 2,
					title: 'Proteìnas (g)',
          value: '',
          unit: '(g)',
				},
        {
          id: 3,
					title: 'Hìdrato de Carbono (g)',
          value: '',
          unit: '(g)',
				},
        {
          id: 4,
					title: 'Grasas (kcal)',
          value: '',
          unit: '(kcal)',
				},
        // {
        //   id: 5,
				// 	title: 'Manganeso (mg)',
        //   value: '',
        //   unit: '(mg)',
				// },
			],
    }
  },
  watch: {
    recordinfnutri(newData) {
      this.infoNutritionals = newData
    },
  },
  methods: {
    validate() {
      const me = this
      me.$v.infoNutritionals.$touch()
      const isValid = !me.$v.infoNutritionals.$invalid

      me.$emit('on-validate', JSON.stringify(me.infoNutritionals), isValid)
      return isValid
    },
  },
  validations: {
    infoNutritionals: {
      required,
      $each: {
        value: {
          required,
        },
      },
    },
  },
}
</script>

<style lang="scss">

</style>

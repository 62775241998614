<template>
  <div>
    <b-row class="">
      <b-col cols="6">
        <b-card
          no-body
          class="card__"
        >
          <b-card-header class="p-1">
            PEDIDOS DEL DÍA
          </b-card-header>
          <div v-if="isBusy">
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-50" />
              <strong>Cargando...</strong>
            </div>
          </div>
          <div
            v-else
            class=""
          >
            <div v-if="series[0].data.length > 0 || series[1].data.length > 0">
              <div
                id="chart"
                class="p-1"
              >
                <apexchart
                  type="bar"
                  height="228"
                  :options="chartOptions"
                  :series="series"
                />
              </div>
            </div>
            <div
              v-else
              class="div__ d-flex align-items-center justify-content-center"
            >
              <div class="box__">
                No existe pedidos programados
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col
        v-if="statistics"
        cols="6"
      >
        <b-row>
          <b-col
            v-for="(item, index) in statistics"
            :key="index"
            cols="12"
          >
            <b-card
              no-body
              class="card__ overflow-hidden mb-1"
              style="max-width: 500px;"
            >
              <b-row no-gutters>
                <b-col cols="3">
                  <b-card-img-lazy
                    :src="item.img"
                    alt="Image"
                    class="card__image rounded-0"
                  />
                </b-col>
                <b-col
                  cols="9"
                  class="p-1"
                >
                  <div class="title">
                    {{ item.title }}
                  </div>
                  <b-row class="text-center">
                    <b-col cols="4">
                      <div class="title2">
                        Desayunos
                      </div>
                      <b-badge :variant="item.color">
                        {{ item.breakfasts.length }}
                      </b-badge>
                    </b-col>
                    <b-col cols="4">
                      <div class="title2">
                        Almuerzos
                      </div>
                      <b-badge :variant="item.color">
                        {{ item.lunches.length }}
                      </b-badge>
                    </b-col>
                    <b-col cols="4">
                      <div class="title2">
                        Cenas
                      </div>
                      <b-badge :variant="item.color">
                        {{ item.dinners.length }}
                      </b-badge>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
	BCard,
	BCardHeader,
	BRow,
	BCol,
  BCardImgLazy,
  BBadge,
  BSpinner,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
		BRow,
		BCol,
		BCard,
		BCardHeader,
    BBadge,
    BSpinner,
		apexchart: VueApexCharts,
    BCardImgLazy,
	},
  props: {
    series: {
      type: Array,
      default: null,
    },
    isBusy: {
			type: Boolean,
			default: null,
		},
    statistics: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
			chartOptions: {
				chart: {
					type: 'bar',
					height: 200,
					toolbar: {
						show: false,
					},
				},
				plotOptions: {
					bar: {
						horizontal: false,
						columnWidth: '60%',
						endingShape: 'rounded',
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					show: true,
					width: 2,
					colors: ['transparent'],
				},
				xaxis: {
					categories: ['Desayunos', 'Almuerzos', 'Cenas'],
				},
				yaxis: {
					title: {
						text: 'Cantidad',
					},
				},
				fill: {
					opacity: 1,
				},
				tooltip: {
					/* y: {
						formatter: function (val) {
							return '$ ' + val + ' thousands'
						},
					}, */
				},
			},
    }
  },
}
</script>

<style lang="scss" scoped>
.card__ {
  box-shadow: 0 5px 11px 0 rgba(145, 144, 144, 0.3), 0 4px 15px 0 rgba(133, 132, 132, 0.15);
}

.card__image {
  width: 100%;
  height: 100%;
}

.title {
  font-size: 18px;
  font-weight: 700;
  color: #636363;
}

.title2 {
  font-size: 14px;
  font-weight: 700;
  color: #636363;
}

.title3 {
  font-size: 14px;
  font-weight: 700;
  color: #636363;
}

.subtitle {
  font-size: 14px;
  color: #636363;
}

.box__ {
  border: 1.5px solid #7367F0;
  padding: 20px;
  color: #7367F0;
  font-weight: bold;
}

.div__ {
  height: 270px;
}
</style>
